import { FloatingCard } from "@cospex/client/reverse/components/Layout";
import DashboardSearch from "@cospex/client/reverse/components/sections/DashboardSearch";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const navigate = useNavigate();

  const onTrack = () => {
    navigate("/dashboard/history");
    return;
  };
  return (
    <FloatingCard sx={{ mb: 3 }}>
      <DashboardSearch onTrack={onTrack} />
    </FloatingCard>
  );
}
