import { API_PATH_ANALYTICS } from "@cospex/client/constants";
import { getToken } from "@cospex/client/context/AuthProvider";
import TextInput from "@cospex/client/forms/TextInput";
import { getItemWithExpiry, setItemWithExpiry } from "@cospex/client/helpers";
import { getCarrier } from "@cospex/client/helpers";
import useAuth from "@cospex/client/hooks/useAuth";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SearchPhoneModal from "@cospex/client/reverse/SearchPhoneModal";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Stack, Typography } from "@mui/material";
import { a, useSpring } from "@react-spring/web";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import intlTelInput, { Plugin } from "intl-tel-input";
import "intl-tel-input/build/js/utils";
import { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import useMeasure from "react-use-measure";
import { z } from "zod";

let iti: Plugin;
type SearchPhoneForm = {
  phoneNumber: string;
  email: string;
};

/**
 * Phone locator component. Has 2 main states:
 *
 * 1. User is logged in, and we can use their email to find the number. In this case, we only need the number.
 *    When the tracking request is successful, we redirect to the dashboard.
 *
 * 2. User is not logged in, and we need their email to find the number. In this case, we need both the number and the email.
 *    The email field is hidden by default, and is only shown when the user starts typing in the number field.
 *    We also show a tracking modal and animation. When the animation finishes, we redirect to the payment page.
 *
 */

interface ISearchPhone {
  business: "tracker" | "reverse";
  onTrack?: () => void;
  onNumberChange?: (number: string) => void;
  onCountryChange?: (country: string) => void;
}

export default function PhoneLocator({
  onTrack,
  business,
  onNumberChange,
  onCountryChange,
}: ISearchPhone) {
  const onBoardingNumber = getItemWithExpiry("onboardingNumber");

  const { t, i18n } = useTranslation();
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const emailRef = useRef<any>(null);
  const [searchPhoneModalOpen, setSearchPhoneModalOpen] = useState(false);
  const [carrierLogo, setCarrierLogo] = useState<any | null>(null);
  const [carrier, setCarrier] = useState<string | null>(null);
  const [measureRef, { height }] = useMeasure();
  const { session } = useAuth();
  const noSession = !session?.email;
  const [trackingError, setTrackingError] = useState<string | boolean>(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [emailFieldVisible, setEmailFieldVisible] = useState(
    noSession && !!onBoardingNumber
  );
  const styles = useSpring({
    height: emailFieldVisible ? height : 0,
  });

  const logoStyles = useSpring({
    from: { height: 0 },
    to: { height: carrierLogo ? "auto" : 0 },
    reset: true,
    reverse: !carrierLogo,
    delay: 200,
  });

  const inputRef = useRef<any>(null);
  useEffect(() => {
    iti = intlTelInput(inputRef.current, {
      initialCountry: "auto",
      geoIpLookup: function (callback) {
        axios
          .get<{ country_code: string }>("https://ipapi.co/json")
          .then(function (res) {
            return res.data;
          })
          .then(function (data) {
            callback(data.country_code);
          })
          .catch(function () {
            callback("fr");
          });
      },
      nationalMode: true,
      formatOnDisplay: true,
      preferredCountries: ["FR", "DE", "GB", "ES", "IT", "BE"],
    });
  }, []);

  const { control, handleSubmit, formState, getValues } =
    useForm<SearchPhoneForm>({
      mode: "onChange",
      defaultValues: {
        phoneNumber: (noSession && onBoardingNumber) || "",
        email: (noSession && getItemWithExpiry("onboardingEmail")) || "",
      },
      resolver: zodResolver(
        z.object({
          phoneNumber: z
            .string()
            .min(1, "phone-form-tracking-invalid")
            .refine(
              () => {
                return iti.isValidNumber();
              },
              {
                message: "phone-form-tracking-invalid",
              }
            ),
          ...(!session?.email && {
            email: z.string().email("phone-form-email-invalid"),
          }),
        })
      ),
    });

  const phoneNumber = useWatch({
    control,
    name: "phoneNumber",
    defaultValue: (noSession && onBoardingNumber) || "",
  });

  useEffect(() => {
    // Hack: Force iti to update its internal state
    // This is necessary because iti's event listeners may not have been set up yet
    // when this effect runs, especially on initial render
    iti.setNumber(phoneNumber);
    if (iti.isValidNumber()) {
      const intlNumber = iti.getNumber();
      const country = iti.getSelectedCountryData();
      if (country) {
        onCountryChange?.(country.name);
      } else {
        onCountryChange?.("");
      }

      axios
        .post<{ carrier: string }>(`/api/${business}/carrier`, {
          phone: intlNumber,
        })
        .then(function (res) {
          const carrierMapping = getCarrier(res.data.carrier);
          if (carrierMapping !== "") {
            setItemWithExpiry("onBoardingProvider", res.data.carrier);
            setCarrier(carrierMapping);
          } else {
            setItemWithExpiry("onBoardingProvider", "");
            setCarrier(null);
          }
        });
    } else {
      onCountryChange?.("");
      setCarrier(null);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (carrier) {
      import(`../assets/carriers/${carrier}.svg`)
        .then((logoImport) => {
          setCarrierLogo(logoImport.default);
        })
        .catch(() => {
          console.error(`Logo not found for carrier: ${carrier}`);
        });
    } else {
      setCarrierLogo(null);
    }
  }, [carrier]);

  const onAnimated = () => {
    setAnimationFinished(true);
    setSearchPhoneModalOpen(false);
  };

  const newUserMutation = useMutation({
    networkMode: "always",
    mutationFn: ({ phoneNumber, email }: SearchPhoneForm) => {
      setSearchPhoneModalOpen(true);
      const intlNumber = intlTelInputUtils.formatNumber(
        phoneNumber,
        iti.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      return axios.post(`/api/${business}/onboarding`, {
        phone: intlNumber,
        email,
        language: i18n.language,
      });
    },
    onSuccess: ({ data: { id } }: any, { phoneNumber, email }) => {
      const intlNumber = intlTelInputUtils.formatNumber(
        phoneNumber,
        iti.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      setItemWithExpiry("onboardingNumber", intlNumber);
      setItemWithExpiry("onboardingEmail", email);
      setItemWithExpiry("onboardingId", id);
      const searchParams = new URLSearchParams(window.location.search);
      const utmSource = searchParams.get("utm_source");
      const utmMedium = searchParams.get("utm_medium");
      const utmCampaign = searchParams.get("utm_campaign");
      const gclid = searchParams.get("gclid");
      const fbclid = searchParams.get("fbclid");
      const msclkid = searchParams.get("msclkid");
      axios.post(API_PATH_ANALYTICS, {
        email: email,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        gclid: gclid,
        fbclid: fbclid,
        msclkid: msclkid,
      });
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
      setTrackingError(apiError || true);
      setSearchPhoneModalOpen(false);
    },
  });

  const loggedInMutation = useMutation({
    mutationFn: ({ phoneNumber }: { phoneNumber: string }) => {
      setSearchPhoneModalOpen(true);
      const intlNumber = intlTelInputUtils.formatNumber(
        phoneNumber,
        iti.getSelectedCountryData().iso2,
        intlTelInputUtils.numberFormat.INTERNATIONAL
      );
      return axios.post(
        `/api/${business}/lookups`,
        {
          phone: intlNumber,
        },
        {
          withCredentials: true,
          headers: { Authorization: "Bearer " + getToken() },
        }
      );
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
      setTrackingError(apiError || true);
    },
  });

  const onSubmit = () => {
    if (session?.email) {
      loggedInMutation.mutate({ phoneNumber: getValues("phoneNumber") });
    } else {
      newUserMutation.mutate({
        phoneNumber: getValues("phoneNumber"),
        email: getValues("email"),
      });
    }
  };
  const onChange = (e: TextInputChangeEvent) => {
    onNumberChange?.(e.target.value);
    if (session?.email) {
      // There's an email, no need to do anything
      return;
    }
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      if (iti.isValidNumber()) {
        setEmailFieldVisible(true);
        emailRef.current?.focus();
      } else {
        setEmailFieldVisible(false);
      }
    }, 200);
  };

  useEffect(() => {
    if (
      animationFinished &&
      (newUserMutation.isSuccess || loggedInMutation.isSuccess)
    ) {
      onTrack?.();
    }
  }, [
    animationFinished,
    newUserMutation.isSuccess,
    loggedInMutation.isSuccess,
  ]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("phoneNumber")!.focus();
    }, 0);
  }, []);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: "4px 4px 0" }}>
          <TextInput
            sx={{
              py: 1.5,
              boxShadow: 2,
            }}
            onChangeCb={onChange}
            formState={formState}
            inputRef={inputRef}
            control={control}
            name="phoneNumber"
            inputProps={{
              inputMode: "numeric",
            }}
            fullWidth
          />
        </Box>
        <a.div style={{ ...styles, padding: "0 4px", overflow: "hidden" }}>
          <div ref={measureRef}>
            <a.div style={logoStyles}>
              <Stack
                p="20px 4px 0"
                flexDirection="row"
                alignItems="center"
                gap="1rem"
              >
                <Typography variant="h6">{t("carrier-title")}</Typography>
                {carrierLogo && carrier && (
                  <img height="40px" src={carrierLogo} alt={carrier} />
                )}
              </Stack>
            </a.div>
            <TextInput
              inputRef={emailRef}
              sx={{
                mt: 2,
                mb: "4px",
                boxShadow: 2,
              }}
              formState={formState}
              control={control}
              name="email"
              label={t("phone-form-email-label")}
              fullWidth
            />
          </div>
        </a.div>
        <Box
          style={{
            padding: "0 4px",
          }}
        >
          <LoadingButton
            sx={{
              mt: 2,
              height: 50,
              boxShadow: 2,
            }}
            disableElevation
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            loading={
              loggedInMutation.isLoading ||
              newUserMutation.isLoading ||
              searchPhoneModalOpen
            }
          >
            <Typography color="white" textTransform="uppercase">
              {t("phone-form-button")}
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
      {trackingError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {typeof trackingError === "string" ? (
            trackingError
          ) : (
            <>
              There has been an issue with your tracking request, please try
              again later. There may be a problem with your connection or an
              issue on our side. If the problem persists, please contact us.
            </>
          )}
        </Alert>
      )}

      {searchPhoneModalOpen && (
        <SearchPhoneModal carrierLogo={carrierLogo} onAnimated={onAnimated} />
      )}
    </>
  );
}
