import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

type LanguageCode = "fr" | "en" | "it";

type SMSTemplate = {
  id: number;
  title: string;
  enabled: boolean;
  translations: Record<LanguageCode, string>;
};

type NameForm = {
  templateId: number;
  firstname?: string;
  lastname?: string;
};

type SearchNotFoundProps = {
  id: string;
  phone?: string;
  smsSent?: boolean;
};

const SearchNotFound = ({ id, phone, smsSent }: SearchNotFoundProps) => {
  const { t, i18n } = useTranslation();
  const result = useQuery({
    queryKey: ["sms-template"],
    queryFn: async () => {
      const response = await axios.get<SMSTemplate[]>(
        "/api/reverse/templates-sms",
        {
          withCredentials: true,
          headers: { Authorization: "Bearer " + getToken() },
        }
      );
      return response.data;
    },
  });

  const [success, setSuccess] = useState(false);

  const newTrackingMutation = useMutation({
    networkMode: "always",
    mutationFn: ({ templateId }: NameForm) => {
      const body = {
        lookupId: id,
        templateId: templateId,
        language: i18n?.language,
      };
      return axios.post("/api/reverse/sms", body, {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      });
    },
    onSuccess: () => {
      setSuccess(true);
    },
    onError: (data: any) => {
      const apiError = data.response?.data?.error;
    },
  });

  const onSubmit: SubmitHandler<NameForm> = (data) =>
    newTrackingMutation.mutate(data);

  const { control, handleSubmit, formState, getValues, setValue } =
    useForm<NameForm>({
      mode: "onTouched",
      resolver: zodResolver(
        z.object({
          templateId: z.number(),
        })
      ),
    });

  useEffect(() => {
    const firstTemplate = result.data?.[0];
    if (getValues().templateId === undefined && firstTemplate) {
      setValue("templateId", firstTemplate.id);
    }
  }, [result.data]);

  return (
    <>
      <Typography variant="h4">
        {t("dashboard-search-title-no-result")}
      </Typography>

      <Typography>
        {t("dashboard-search-number")} {phone}
      </Typography>

      <Card sx={{ minWidth: 275, maxWidth: 410 }}>
        <CardContent>
          <Stack rowGap={2}>
            <Typography>{t("dashboard-search-note-one")}</Typography>
            <Typography>{t("dashboard-search-note-two")}</Typography>
          </Stack>
        </CardContent>
      </Card>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Controller
            name="templateId"
            control={control}
            render={({ field }) => (
              <RadioGroup
                value={field.value?.toString() || null} // Convert current value to string
                onChange={(e) => field.onChange(parseInt(e.target.value))} // Parse new value to number
              >
                {result.data?.map((template: SMSTemplate) => {
                  const html = template.translations[i18n.language];
                  return (
                    <FormControlLabel
                      sx={{
                        m: 0,
                        mb: 2,
                      }}
                      key={template.id}
                      value={template.id.toString()} // Convert template id to string
                      control={<></>}
                      label={
                        <Card
                          sx={{
                            border:
                              field.value === template.id
                                ? "2px solid"
                                : "2px solid transparent",
                            borderColor:
                              field.value === template.id
                                ? "primary.main"
                                : "transparent",
                          }}
                          onClick={() => field.onChange(template.id)} // Set selected template on click
                        >
                          <CardContent>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: html,
                              }}
                            />
                          </CardContent>
                        </Card>
                      }
                    />
                  );
                })}
              </RadioGroup>
            )}
          />
        </Stack>
        <Stack
          alignItems="center"
          direction="column"
          sx={{
            padding: "0 4px",
            gap: "1rem",
          }}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={smsSent || newTrackingMutation.isSuccess}
            loading={newTrackingMutation.isLoading}
            sx={{
              backgroundColor: success || smsSent ? "green" : undefined,
              "&.Mui-disabled": {
                backgroundColor: "green",
                color: "white",
              },
              "&.MuiLoadingButton-loading": {
                backgroundColor: colors.grey[200],
              },
            }}
          >
            {success || smsSent ? (
              <Typography color="white">
                {t("dashboard-search-button-success")}
              </Typography>
            ) : (
              <Typography color="white">
                {t("dashboard-search-button-send")}
              </Typography>
            )}
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
};

export default SearchNotFound;
