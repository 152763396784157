import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { FloatingCard } from "@cospex/client/reverse/components/Layout";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Skeleton, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function Account() {
  const { t } = useTranslation();
  const { isLoading, error, data } = useQuery({
    queryKey: ["accountData"],
    queryFn: async () => {
      const res = await axios.get<Account>("/api/dashboard-account", {
        withCredentials: true,
        headers: { Authorization: "Bearer " + getToken() },
      });
      return res.data;
    },
  });

  const resetPassMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post("/api/request-password", null, {
        headers: { Authorization: "Bearer " + getToken() },
      });
      return response.data;
    },
  });

  const handleResetPassword = () => {
    resetPassMutation.mutate();
  };

  if (isLoading)
    return (
      <>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ mb: 4 }}
        />
      </>
    );

  if (error || !data)
    return (
      <Alert severity="error">
        There was a problem with your request. Please try again later. If the
        problem persists, contact support.
      </Alert>
    );

  return (
    <>
      <FloatingCard sx={{ mb: 3 }}>
        <Typography variant="h3" color="primary.main" mb={2}>
          {t("dashboard-my-account")}
        </Typography>
        <Typography variant="body1" mb={3}>
          <strong>{t("dashboard-account-email-title")}</strong>
          <br />
          {data.email}
        </Typography>
        <Box>
          <strong>{t("dashboard-account-reset-title")}</strong>
          <br />
          <Box mb={1}>{t("dashboard-account-reset-description")}</Box>
          <LoadingButton
            variant="contained"
            disableElevation
            onClick={handleResetPassword}
            disabled={resetPassMutation.isLoading}
            sx={{
              color: "white",
              backgroundColor: resetPassMutation.isSuccess
                ? "success.main"
                : "primary.main",
            }}
          >
            <span>
              {resetPassMutation.isSuccess
                ? t("dashboard-account-reset-success")
                : t("dashboard-account-reset-button")}
            </span>
          </LoadingButton>
        </Box>
      </FloatingCard>
      {/* {data.nextRebill ? (
        <>
          <FloatingCard sx={{ mb: 3 }}>
            <Typography variant="h3" color="primary.main" mb={2}>
              {t("dashboard-account-your-invoice")}
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1">
                <strong>{t("dashboard-account-associated-cc")}</strong>
              </Typography>
              <Chip label={data.cardPan} />
            </Box>
            <Typography variant="body1" mb={3}>
              <strong>{t("dashboard-account-next-billing")}</strong>
              <br />
              {data.nextRebill}
            </Typography>
            <Typography variant="body1">
              <strong>{t("dashboard-account-payment-on")}</strong>
              <br />
              {data.transactions[0].createdAt} -{" "}
              {i18n.language === "en"
                ? "€" + data.transactions[0].amount
                : data.transactions[0].amount + "€"}
            </Typography>
          </FloatingCard>
          <FloatingCard>
            <Typography variant="h3" color="error.main" mb={2}>
              {t("dashboard-account-unsubscribe")}
            </Typography>
            <Typography variant="body1" mb={3}>
              {t("dashboard-account-unsubscribe-description")}
            </Typography>
            <Button
              variant="contained"
              color="error"
              disableElevation
              component="a"
              href="/unsubscribe"
            >
              {t("dashboard-account-unsubscribe")}
            </Button>
          </FloatingCard>
        </>
      ) : (
        <FloatingCard sx={{ mb: 3 }}>
          {t("dashboard-account-no-subscription")}
        </FloatingCard>
      )} */}
    </>
  );
}
