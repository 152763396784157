import { getToken } from "@cospex/client/context/AuthProvider";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SearchNotFound from "@cospex/client/reverse/components/SearchNotFound";
import { AccountCircleRounded, Info } from "@mui/icons-material";
import {
  Box,
  Button,
  Skeleton,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const StyledBoxDetails = styled(Box)`
  display: flex;
  width: 290px;
  height: auto;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--neutral-40, #dde1e6);
  background: var(--neutral-10, #fff);

  /* Shadow 1 */
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
`;

type SearchDisplayProps = {
  found: boolean;
  phone: string;
  lastname?: string;
  firstname?: string;
  carrier?: string;
  gender?: string;
  searchDate: string;
  address?: string;
  smsSent: boolean;
};

const HistoryResult = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const result = useQuery({
    queryKey: [`history-${id}`],
    queryFn: async () => {
      return (
        await axios.get<SearchDisplayProps>(`/api/reverse/lookups/${id}`, {
          withCredentials: true,
          headers: { Authorization: "Bearer " + getToken() },
        })
      ).data;
    },
  });

  if (result.isLoading)
    return (
      <Skeleton
        variant="rectangular"
        width="100%"
        height={300}
        sx={{ mb: 4 }}
      />
    );

  const data = result.data;

  return (
    <Stack sx={{ alignItems: "center", gap: "1rem" }}>
      {data?.found ? (
        <>
          <Typography variant="h4">{t("dashboard-search-title")}</Typography>
          <Typography>
            {t("dashboard-search-number")} {data?.phone}
          </Typography>
          <Stack sx={{ flexDirection: "row", gap: "1rem" }}>
            <AccountCircleRounded sx={{ fontSize: "3rem" }} />
            <Box>
              <Typography>
                {data?.firstname} {data?.lastname}
              </Typography>
              <Typography>
                {t("dashboard-search-date")}
                {data?.searchDate}
              </Typography>
            </Box>
          </Stack>
          <StyledBoxDetails>
            {t("dashboard-search-surname")} {data?.lastname}
          </StyledBoxDetails>
          <StyledBoxDetails>
            {t("dashboard-search-firstname")} {data?.firstname}
          </StyledBoxDetails>
          <StyledBoxDetails>
            {t("dashboard-search-carrier")} {data?.carrier}
          </StyledBoxDetails>
          <StyledBoxDetails>
            {t("dashboard-search-gender")} {data?.gender}
          </StyledBoxDetails>
          <StyledBoxDetails>
            {t("dashboard-search-address")} {data?.address}
          </StyledBoxDetails>
          <Button
            disableElevation
            to="/dashboard/history"
            component={Link}
            variant="contained"
            sx={{ backgroundColor: "green" }}
            endIcon={<Info sx={{ color: "white" }} />}
          >
            <Typography color="white">
              {t("dashboard-search-success")}
            </Typography>
          </Button>
        </>
      ) : (
        <SearchNotFound id={id} phone={data?.phone} smsSent={data?.smsSent} />
      )}
    </Stack>
  );
};

export default HistoryResult;
