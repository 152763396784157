import PhoneGraphic from "@cospex/client/reverse/components/PhoneGraphic";
import TiltPlane from "@cospex/client/reverse/components/TiltPane";
import HeroImageA from "@cospex/client/reverse/img/hero-background.webp";
import HeroImageB from "@cospex/client/reverse/img/hero-foreground.webp";
import { Box, Stack } from "@mui/material";

interface HeroProps {
  number: string;
  country: string;
}
const Hero = ({ number, country }: HeroProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        marginX: "auto",
        aspectRatio: "812/864",
        maxWidth: 500,
      }}
    >
      <TiltPlane>
        <Stack sx={{ height: "85%", alignItems: "center" }}>
          <Box
            component="img"
            src={HeroImageA}
            alt="Hero Image"
            sx={{ width: "auto", height: "100%" }}
          />
        </Stack>
        <PhoneGraphic
          number={number}
          country={country}
          phoneImageSrc={HeroImageB}
        />
      </TiltPlane>
    </Box>
  );
};

export default Hero;
