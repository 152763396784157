import ReviewCarousel from "@cospex/client/components/ReviewCarousel";
import PaymentForm from "@cospex/client/forms/payment";
import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SocialProof from "@cospex/client/reverse/SocialProof";
import { Section, ShadowCard } from "@cospex/client/reverse/components/Layout";
import {
  SectionSubtitle,
  SectionTitle,
} from "@cospex/client/reverse/components/Layout";
import PaymentStepper from "@cospex/client/reverse/components/PaymentStepper";
import Pricing from "@cospex/client/reverse/components/Pricing";
import HowDoesItWork from "@cospex/client/reverse/components/sections/HowDoesItWork";
import LocationImage from "@cospex/client/reverse/img/location.png";
import PlaceMarkerImg from "@cospex/client/reverse/img/place-marker.png";
import {
  Alert,
  Box,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  styled,
} from "@mui/material";

const BlurText = styled(Box)({
  textShadow: "0 0 8px #000",
  color: "transparent",
});

const Payment = () => {
  const { t } = useTranslation();

  const onboardingNumber = getItemWithExpiry("onboardingNumber");
  const email = getItemWithExpiry("onboardingEmail");
  const id = getItemWithExpiry("onboardingId");

  const noDetailsError = !onboardingNumber || !email || !id;

  if (noDetailsError) {
    return (
      <Container>
        <Alert severity="error">
          Error, please go <Link href="/">home</Link> and try again.
        </Alert>
      </Container>
    );
  }

  return (
    <>
      <PaymentStepper />
      <Container
        sx={{
          mb: 4,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ShadowCard sx={{ height: "100%", p: 0 }}>
              <PaymentForm onPaymentSuccessRedirectPath="/dashboard/history" />
            </ShadowCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ShadowCard sx={{ height: "100%", alignItems: "center", pt: 0 }}>
              <Stack
                flexDirection="row"
                gap="0.5rem"
                sx={{ fontSize: { xs: "14px", sm: "unset" } }}
              >
                <Typography variant="h5" sx={{ fontSize: "inherit" }}>
                  <b>{t("phone-to-locate-details")}</b>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ fontSize: "inherit", color: "primary.main" }}
                >
                  <b>{onboardingNumber}</b>
                </Typography>
              </Stack>
              <Box
                mt="1rem"
                display="block"
                width="100%"
                sx={{
                  height: 246,
                  backgroundImage: `url(${LocationImage})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 2,
                  position: "relative",
                }}
                mb={4}
              ></Box>
              <Grid container mb={5.5} mt="2rem" sx={{ textAlign: "center" }}>
                <Grid item xs={6}>
                  <Stack rowGap="1rem">
                    <Box>
                      <Typography variant="body1">
                        {t("phone-location-surname")}:
                      </Typography>
                      <BlurText>xxxxx</BlurText>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        {t("phone-location-address")}:
                      </Typography>
                      <BlurText>xxxxx</BlurText>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack rowGap="1rem">
                    <Box>
                      <Typography variant="body1">
                        {t("phone-location-firstname")}:
                      </Typography>
                      <BlurText>xxxxx</BlurText>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        {t("phone-location-gender")}:
                      </Typography>
                      <BlurText>xxxxx</BlurText>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Stack flexDirection="row" mt="1.5rem">
                <Stack justifyContent="center" m="1rem">
                  <Box
                    component="img"
                    height="24px"
                    width="24px"
                    src={PlaceMarkerImg}
                    alt="place marker"
                  />
                </Stack>
                <Stack gap="5px">
                  <Typography variant="h6">
                    {t("phone-location-precise")}
                  </Typography>
                  <Typography variant="body2">
                    {t("phone-location-precise-desc")}
                  </Typography>
                </Stack>
              </Stack>
            </ShadowCard>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          pt: {
            xs: 0,
            sm: 4,
            md: 8,
          },
        }}
      >
        <SocialProof />
      </Box>
      <Section filled noPaddingBottom>
        <SectionSubtitle>{t("home-testimonials-subtitle")}</SectionSubtitle>
        <SectionTitle>{t("home-testimonials-title")}</SectionTitle>
        <ReviewCarousel />
      </Section>
      <HowDoesItWork />
      <Section noPaddingBottom>
        <Stack alignItems="center">
          <Pricing />
        </Stack>
      </Section>
    </>
  );
};

export default Payment;
