import useTranslation from "@cospex/client/hooks/useTranslation";
import UniqueTechImg from "@cospex/client/reverse/img/unique-tech.webp";
import { Box, Container, Stack, Typography } from "@mui/material";

export default function UniqueTechnology() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        px: 4,
        py: {
          xs: 4,
          sm: 6,
          md: 8,
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: { xs: "column", sm: "row", alignItems: "center" },
          textAlign: {
            xs: "center",
            sm: "left",
          },
        }}
      >
        <Stack sx={{ flex: "1 1 0", justifyContent: "center" }}>
          <Stack
            sx={{
              rowGap: "1rem",
              width: "80%",
              margin: {
                xs: "auto",
                sm: 0,
              },
            }}
          >
            <Typography variant="h2">{t("unique-technology-title")}</Typography>
            <Typography>{t("unique-technology-one")}</Typography>
            <Typography>{t("unique-technology-two")}</Typography>
            <Typography>{t("unique-technology-three")}</Typography>
            <br />
          </Stack>
        </Stack>
        <Stack flex="1 1 0">
          <Stack
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="2rem"
          >
            <Box
              component="img"
              src={UniqueTechImg}
              alt="Service Compatible Image"
              sx={{ width: "100%", height: "100%" }}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          alignItems: "center",
          opacity: 0.4,
          p: 3,
          mt: 2,
          backgroundColor: "#dedede",
          borderRadius: 2,
        }}
      >
        <Typography>{t("unique-technology-notes")}</Typography>
      </Stack>
    </Container>
  );
}
